import Lottie from "lottie-react";
import { useRouter } from "next/router";
import { useContext } from "react";

import { trackleadform } from "@/analytics/analytics";

import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import Typography from "@/design-system/Typography";

import { useAppSelector } from "@/hooks/hooks";
import useScreenDuration from "@/hooks/useScreenDuration";

import { PreviousRouteProvider } from "@/pages/_app";

import { getScrollPositionByPercentage } from "@/utils/utils";

import { theme } from "@/theme";

import WhatsappLottie from "../../../public/lottie/whatsapp_lottie.json";

const WhatsappWidget = () => {
  const mobile = 919319745217;
  const router = useRouter();
  const loginDetails = useAppSelector((state) => state.rootReducer.auth);
  const { previousRoute } = useContext(PreviousRouteProvider);
  const screenTime = useScreenDuration();

  const handleLinkClick = (e) => {
    const { userId, userProfile, userData, isAuthenticated } = loginDetails;
    const linkClickTrackObj = {
      link_text: e.currentTarget.innerText,
      user_id: userId,
      name: userProfile.firstName,
      email: userData.emailId,
      Page_path: window.location.pathname,
      Page_Referrer: previousRoute,
      percent_scrolled: getScrollPositionByPercentage(),
      on_screen_duration: screenTime.formattedTime,
      loginDetails: userData,
      isLoggedIn: isAuthenticated,
      Current_Page: window.location.href,
    };
    trackleadform("Link_Click", linkClickTrackObj);
  };
  return (
    <Box
      position={"fixed"}
      bottom={48}
      right={{ _: "32px", md: "80px" }}
      zIndex={200}
      display={{ _: "none", md: "flex" }}
      borderRadius={"32px"}
    >
      <Box
        as={"a"}
        href={`https://wa.me/${mobile}?text=https://www.novatr.com${router.asPath}%0A%0AHey%2C%0AI%20read%20about%20your%20program%20on%20Novatr.com%20and%20had%20a%20few%20questions%20about%20it.%20Can%20you%20help%20me%3F`}
        target="_blank"
        marginLeft={"auto"}
        className="chat_with_us"
        onClick={handleLinkClick}
      >
        <UnstyledButton className="chat_with_us">
          <Box
            display={"flex"}
            paddingLeft={"24px"}
            paddingRight={"8px"}
            paddingTop={"2px"}
            paddingBottom={"2px"}
            alignItems={"center"}
            style={{ gap: "8px" }}
            background={"linear-gradient(90deg, #FFF 0%, #DBFFDF 100%)"}
            borderRadius={"99px"}
            border={"1px solid #9DD8A3"}
            width="max-content"
          >
            {/* <Box
            mt={"auto"}
            mb={"auto"}
            mr={"8px"}
            pt={"2px"}
            pb={"4px"}
            pl={"8px"}
            pr={"8px"}
            borderRadius={"8px"}
            background={
              isBim ? theme.colors.purple[800] : theme.colors.blue[800]
            }
          > */}
            <Typography
              color={theme.colors.gray[900]}
              varient="bodyxs"
              weightVarient="semibold"
              as={"h5"}
              className="chat_with_us"
            >
              Chat With Us
            </Typography>
            <Lottie animationData={WhatsappLottie} style={{ width: "52px", height: "52px" }} />
          </Box>
        </UnstyledButton>
      </Box>
    </Box>
  );
};

export default WhatsappWidget;
